import { ReactNode, ReactElement, useRef } from 'react'

import { Header } from '../../components/header'
import { Footer } from '../../components/footer'
import { useUpdateHeader } from '../../hooks/use-update-header'
import { HeaderThemeValues, HEADER_THEMES } from '../../utils/constants'

type WebsiteLayoutProps = {
  children: ReactNode
  shouldChangeBgColor?: boolean
  headerInitialTheme?: HeaderThemeValues
}

export const WebsiteLayout = ({
  shouldChangeBgColor = false,
  headerInitialTheme,
  children,
}: WebsiteLayoutProps) => {
  const footerRef = useRef<HTMLElement | null>(null)
  useUpdateHeader(footerRef, HEADER_THEMES.EXT_DEEP_BLUE)
  return (
    <>
      <Header shouldChangeBgColor={shouldChangeBgColor} headerInitialTheme={headerInitialTheme} />
      <main>{children}</main>
      <Footer ref={footerRef} />
    </>
  )
}

export const WebsitePageLayout = (page: ReactElement) => (
  <WebsiteLayout shouldChangeBgColor headerInitialTheme={HEADER_THEMES.NEUTRAL_BACKGROUND}>
    {page}
  </WebsiteLayout>
)

WebsiteLayout.getLayout = WebsitePageLayout

export default WebsiteLayout
