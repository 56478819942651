import NextLink, { LinkProps } from 'next/link'
import classnames from 'classnames'
import React, { forwardRef, useMemo, useRef, useImperativeHandle, Context } from 'react'
import {
  formatUrl,
  isExternalUrl,
  AmplitudeContext,
  useClickEventTracking,
  ContextType,
} from '@platform/utils'

import styles from './link.module.scss'

export enum LinkSizes {
  Medium = 'medium',
  Large = 'large',
}

type BaseProps = {
  className?: string
  href: string
  icon?: React.ElementType
  endIcon?: React.ElementType
  children?: React.ReactNode
  trackingIdentifier?: string
  size?: 'medium' | 'large'
  prefetch?: boolean
  isExternal?: boolean
}

type LinkAsLink = BaseProps & Omit<LinkProps, keyof BaseProps>

type LinkAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps>

export type LinkComponentProps = LinkAsExternal | LinkAsLink

export const Link = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  (
    {
      className = '',
      href,
      children,
      icon,
      endIcon,
      trackingIdentifier,
      size = 'medium',
      prefetch,
      isExternal = false,
      ...rest
    },
    ref
  ) => {
    const classes = classnames(styles['link'], styles[size], className)

    const innerRef = useRef<HTMLAnchorElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLAnchorElement)

    useClickEventTracking(
      innerRef,
      AmplitudeContext as unknown as Context<ContextType>,
      'link_click',
      {
        id: trackingIdentifier,
        label: children,
      }
    )

    const formattedUrl = isExternal ? href : formatUrl(href)

    const renderChildren = useMemo(() => {
      const Icon = icon as React.ElementType
      const EndIcon = endIcon as React.ElementType
      return (
        <>
          {icon && <Icon className={classnames(styles['icon'], styles['start-icon'])} />}
          {children}
          {endIcon && <EndIcon className={classnames(styles['icon'], styles['end-icon'])} />}
        </>
      )
    }, [icon, endIcon, children])

    if (isExternalUrl(href) || isExternal) {
      return (
        <a
          href={formattedUrl}
          className={classes}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          {...rest}
        >
          {renderChildren}
        </a>
      )
    }

    return (
      <NextLink
        href={formattedUrl}
        prefetch={prefetch}
        className={classes}
        ref={innerRef as React.Ref<HTMLAnchorElement>}
        {...rest}
      >
        {renderChildren}
      </NextLink>
    )
  }
)

export default Link
