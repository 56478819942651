import { useIsomorphicLayoutEffect } from '@platform/utils'
import { useQueryClient } from '@tanstack/react-query'
import type { MutableRefObject } from 'react'

const HEADER_HEIGHT = 72
// This hook is used to dynamic update header color between sections
export const useUpdateHeader = (
  referenceElement: MutableRefObject<Element | null>,
  color: string
) => {
  const queryClient = useQueryClient()

  useIsomorphicLayoutEffect(() => {
    const handleScroll = () => {
      if (referenceElement.current) {
        const { top, bottom } = referenceElement.current.getBoundingClientRect()
        if (top - HEADER_HEIGHT < 0 && bottom - HEADER_HEIGHT > 0) {
          queryClient.setQueryData(['HeaderTheme'], {
            backgroundColor: color,
          })
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [referenceElement.current, color])
}
