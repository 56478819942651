import {
  LogoBenepass,
  LogoGBE,
  LogoHapana,
  LogoHighnote,
  LogoLightyear,
  LogoLoadsmart,
  LogoShareThis,
  LogoTheNewLifeChallenge,
  LogoScholarsOfFinance,
  LogoDvora,
  LogoPythonic,
  LogoSwingDev,
  LogoVitalTrace,
  LogoSoundOff
} from '../assets/icons'

type HeaderThemeKeys = keyof typeof HEADER_THEMES
export type HeaderThemeValues = typeof HEADER_THEMES[HeaderThemeKeys]

export const HEADER_THEMES = {
  PRIMARY_BRAND_BASE: 'primary-brand-base',
  PRIMARY_BRAND_BASE_ALT: 'primary-brand-base-alt',
  PRIMARY_BRAND_LOW: 'primary-brand-low',
  EXT_BRAND_TEAL: 'ext-brand-teal',
  EXT_BRAND_TEAL_ALT: 'ext-brand-teal-alt',
  EXT_DEEP_BLUE: 'ext-deep-blue',
  SECONDARY_BRAND_BASE: 'secondary-brand-base',
  SECONDARY_BRAND_LOW: 'secondary-brand-low',
  NEUTRAL_BACKGROUND: 'neutral-background',
  HIGHLIGHT_ALT_BASE: 'highlight-alt-base',
  BLURRED: 'blurred',
}

export const BRANDS_INFO = {
  loadsmart: {
    icon: LogoLoadsmart,
    id: 'loadsmart',
    href: 'https://loadsmart.com/',
  },
  benepass: {
    icon: LogoBenepass,
    id: 'benepass',
    href: 'https://www.getbenepass.com/',
  },
  shareThis: {
    icon: LogoShareThis,
    id: 'sharethis',
    href: 'https://sharethis.com/',
  },
  gbe: {
    icon: LogoGBE,
    id: 'gbe',
    href: 'https://greatbigevents.com/',
  },
  hapana: {
    icon: LogoHapana,
    id: 'hapana',
    href: 'https://hapana.com/',
  },
  highnote: {
    icon: LogoHighnote,
    id: 'highnote',
    href: 'https://highnote.io/',
  },
  theNewLifeChallenge: {
    icon: LogoTheNewLifeChallenge,
    id: 'theNewLifeChallenge',
    href: 'https://getthenewlife.com/',
  },
  lightyear: {
    icon: LogoLightyear,
    id: 'lightyear',
    href: 'https://www.lightyear.co/',
  },
  scholarsOfFinance: {
    icon: LogoScholarsOfFinance,
    id: 'scholarsOfFinance',
    href: 'https://scholarsoffinance.org/',
  },
  dvora: {
    icon: LogoDvora,
    id: 'dvora',
    href: 'https://www.dvoralife.com/',
  },
  pythonic: {
    icon: LogoPythonic,
    id: 'pythonic',
    href: 'https://pythonic.ai/',
  },
  swingDev: {
    icon: LogoSwingDev,
    id: 'swingDev',
    href: 'https://www.swing.dev/',
  },
  vitalTrace: {
    icon: LogoVitalTrace,
    id: 'vitalTrace',
    href: 'https://www.vitaltrace.com.au/',
  },
  soundOff: {
    icon: LogoSoundOff,
    id: 'soundOff',
    href: 'https://sound-off.com/',
  }
}
