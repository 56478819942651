import classnames from 'classnames'
import { IconButton, Logo } from '@platform/ui-kit'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@icons'
import { forwardRef } from 'react'

import { WebsiteLink } from '../website-link'

import styles from './styles.module.scss'

const NAVIGATION_ITEMS = [
  {
    label: 'Olby',
    items: [
      { label: 'About Us', href: '/about-us' },
      { label: 'Blog', href: '/blog' },
      { label: 'FAQ', href: '/faq/talent' },
    ],
  },
  {
    label: 'Talent',
    items: [
      { label: 'How it Works', href: '/for-talent' },
      {
        label: 'Join Us',
        href: `${process.env.NEXT_PUBLIC_TALENT_URL}/sign-up?utm_source=site&utm_medium=referral`,
        isExternal: true,
      },
      { label: 'Login', href: `${process.env.NEXT_PUBLIC_TALENT_URL}`, isExternal: true },
    ],
  },
  {
    label: 'Companies',
    items: [
      { label: 'How it Works', href: '/for-companies' },
      { label: 'Case Studies', href: '/case-studies' },
      { label: 'Login', href: `${process.env.NEXT_PUBLIC_COMPANY_URL}`, isExternal: true },
    ],
  },
  {
    label: 'Connect',
    items: [
      { label: 'Press', href: '/press' },
      { label: 'Contact Us', href: '/contact-us' },
    ],
  },
]

const OLBY_SOCIAL_MEDIA_ITEMS = [
  {
    label: 'LinkedIn',
    icon: LinkedInIcon,
    href: 'https://www.linkedin.com/company/beolby/',
    isExternal: true,
  },
  { label: 'Twitter', icon: TwitterIcon, href: 'https://twitter.com/beolby' },
  {
    label: 'Instagram',
    icon: InstagramIcon,
    href: 'https://www.instagram.com/beolby/',
    isExternal: true,
  },
  {
    label: 'Facebook',
    icon: FacebookIcon,
    href: 'https://www.facebook.com/beolby',
    isExternal: true,
  },
  {
    label: 'YouTube',
    icon: YoutubeIcon,
    href: 'https://www.youtube.com/@beolby',
    isExternal: true,
  },
]

const POLICIES_ITEMS = [
  { label: 'Privacy Policy', href: '/privacy-policy' },
  { label: 'Cookie Policy', href: '/cookie-policy' },
]

type FooterProps = {
  className?: string
}
export const Footer = forwardRef<HTMLElement, FooterProps>(({ className }, ref) => {
  return (
    <footer className={classnames(styles['footer'], className)} ref={ref}>
      <div className={styles['footer-wrapper']}>
        <nav className={styles['footer-navigation']}>
          <ul className={styles['main-content-nav-container']}>
            {NAVIGATION_ITEMS.map(sectionItem => (
              <li key={sectionItem.label} className={styles['section-container']}>
                <p className={styles['section-title']}>{sectionItem.label}</p>
                {sectionItem.items.map(item => (
                  <WebsiteLink
                    key={item.label}
                    href={item.href}
                    className={styles['link']}
                    prefetch={false}
                    isExternal={item.isExternal}
                  >
                    {item.label}
                  </WebsiteLink>
                ))}
              </li>
            ))}
          </ul>
          <div className={styles['additional-content-nav-container']}>
            <Logo isIconLogo variant="secondary" className={styles['logo']} />
            <ul className={styles['policy-container']}>
              {POLICIES_ITEMS.map(policyItem => (
                <li key={policyItem.label}>
                  <WebsiteLink href={policyItem.href} className={styles['link']} prefetch={false}>
                    {policyItem.label}
                  </WebsiteLink>
                </li>
              ))}
            </ul>
            <ul className={styles['social-media-container']}>
              {OLBY_SOCIAL_MEDIA_ITEMS.map(mediaItem => (
                <li key={mediaItem.label}>
                  <IconButton
                    key={mediaItem.label}
                    label={mediaItem.label}
                    variant="ghost-on-brand"
                    size="large"
                    isExternalHref
                    target="_blank"
                    rel="noopener noreferrer"
                    icon={mediaItem.icon}
                    href={mediaItem.href}
                  />
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </footer>
  )
})

export default Footer
