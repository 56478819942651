import { ButtonOutlineVariant, ButtonVariant, LogoVariants } from '@platform/ui-kit'

import {
  WebsiteButtonOutlineVariant,
  WebsiteButtonVariant,
} from '../components/website-kit/website-button'

import { HEADER_THEMES, HeaderThemeValues } from './constants'

type HeaderThemeQueryProps = {
  backgroundColor: string
}

const DARK_BACKGROUNDS = [HEADER_THEMES.PRIMARY_BRAND_BASE, HEADER_THEMES.EXT_BRAND_TEAL]

const LIGHT_BACKGROUNDS = [
  HEADER_THEMES.NEUTRAL_BACKGROUND,
  HEADER_THEMES.PRIMARY_BRAND_LOW,
  HEADER_THEMES.SECONDARY_BRAND_LOW,
  HEADER_THEMES.SECONDARY_BRAND_BASE,
  HEADER_THEMES.HIGHLIGHT_ALT_BASE,
]

const ALTERNATIVE_BACKGROUNDS = [
  HEADER_THEMES.PRIMARY_BRAND_BASE_ALT,
  HEADER_THEMES.EXT_BRAND_TEAL_ALT,
  HEADER_THEMES.EXT_DEEP_BLUE,
]

export type FormatHeaderProps = {
  shouldChangeBgColor?: boolean
  data: HeaderThemeQueryProps
  headerInitialTheme?: HeaderThemeValues
}

const handleThemesByBgColor = (backgroundColor: string) => {
  if (DARK_BACKGROUNDS.includes(backgroundColor)) {
    return {
      buttonNavVariant: {
        variant: WebsiteButtonVariant.GhostOnBrand,
        outlineVariant: WebsiteButtonOutlineVariant.Neutral,
      },
      logoVariant: LogoVariants.Secondary,
      hireOlbyButtonVariant: {
        variant: ButtonVariant.Secondary,
        outlineVariant: ButtonOutlineVariant.Neutral,
      },
    }
  }

  if (LIGHT_BACKGROUNDS.includes(backgroundColor)) {
    return {
      buttonNavVariant: {
        variant: WebsiteButtonVariant.Ghost,
        outlineVariant: WebsiteButtonOutlineVariant.Primary,
      },
      logoVariant: LogoVariants.Primary,
      hireOlbyButtonVariant: {
        variant: ButtonVariant.Primary,
        outlineVariant: ButtonOutlineVariant.Primary,
      },
    }
  }

  if (ALTERNATIVE_BACKGROUNDS.includes(backgroundColor)) {
    return {
      buttonNavVariant: {
        variant: WebsiteButtonVariant.GhostOnBrand,
        outlineVariant: WebsiteButtonOutlineVariant.Neutral,
      },
      logoVariant: LogoVariants.White,
      hireOlbyButtonVariant: {
        variant: ButtonVariant.HighlightAlt,
        outlineVariant: ButtonOutlineVariant.Neutral,
      },
    }
  }

  return {
    buttonNavVariant: {
      variant: WebsiteButtonVariant.GhostOnBrand,
      outlineVariant: WebsiteButtonOutlineVariant.Neutral,
    },
    logoVariant: LogoVariants.White,
    hireOlbyButtonVariant: {
      variant: ButtonVariant.Secondary,
      outlineVariant: ButtonOutlineVariant.Neutral,
    },
  }
}

export const formatHeaderColors = ({
  shouldChangeBgColor,
  data,
  headerInitialTheme,
}: FormatHeaderProps) => {
  if (headerInitialTheme && !data?.backgroundColor) {
    return { headerStyles: headerInitialTheme, ...handleThemesByBgColor(headerInitialTheme) }
  }

  const defaultProps = {
    headerStyles: 'transparent',
    buttonNavVariant: {
      variant: WebsiteButtonVariant.GhostOnBrand,
      outlineVariant: WebsiteButtonOutlineVariant.Default,
    },
    logoVariant: LogoVariants.Secondary,
    hireOlbyButtonVariant: {
      variant: ButtonVariant.Secondary,
      outlineVariant: ButtonOutlineVariant.Default,
    },
  }

  if (!shouldChangeBgColor) {
    return defaultProps
  }

  if (!data) {
    return defaultProps
  }

  const { backgroundColor } = data as HeaderThemeQueryProps

  if (!backgroundColor) {
    return defaultProps
  }

  return {
    headerStyles: backgroundColor,
    ...handleThemesByBgColor(backgroundColor),
  }
}
