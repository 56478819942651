import { useIsomorphicLayoutEffect } from '@platform/utils'
import { useQueryClient } from '@tanstack/react-query'

export const useCleanHeader = () => {
  const queryClient = useQueryClient()

  useIsomorphicLayoutEffect(() => {
    return () => {
      queryClient.setQueryData(['HeaderTheme'], {
        backgroundColor: undefined,
      })
    }
  }, [])
}
