import { useEffect, useState } from 'react'

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowHeight, setWindowHeight] = useState<number | undefined>(undefined)
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined)
  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowHeight(window.innerWidth)
      setWindowWidth(window.innerWidth)
    }
    // Add event listener
    window.addEventListener('resize', handleResize, { passive: true })
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount
  return { windowWidth, windowHeight }
}

export default useWindowSize
