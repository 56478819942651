import NextLink, { LinkProps } from 'next/link'
import classnames from 'classnames'
import React, { forwardRef, useRef, useImperativeHandle, Context } from 'react'
import {
  formatUrl,
  isExternalUrl,
  AmplitudeContext,
  useClickEventTracking,
  ContextType,
} from '@platform/utils'

import styles from './styles.module.scss'

type BaseProps = {
  className?: string
  href: string
  children?: React.ReactNode
  trackingIdentifier?: string
  prefetch?: boolean
  isExternal?: boolean
}

type LinkAsLink = BaseProps & Omit<LinkProps, keyof BaseProps>

type LinkAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps>

export type LinkComponentProps = LinkAsExternal | LinkAsLink

export const WebsiteLink = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  ({ className = '', href, children, trackingIdentifier, prefetch, isExternal, ...rest }, ref) => {
    const classes = classnames(styles['link'], className)

    const innerRef = useRef<HTMLAnchorElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLAnchorElement)

    useClickEventTracking(
      innerRef,
      AmplitudeContext as unknown as Context<ContextType>,
      'link_click',
      {
        id: trackingIdentifier,
      }
    )

    const formattedUrl = isExternal ? href : formatUrl(href)

    if (isExternalUrl(href) || isExternal) {
      return (
        <a
          href={formattedUrl}
          className={classes}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          {...rest}
        >
          <p className={styles['link-text']}>{children}</p>
        </a>
      )
    }

    return (
      <NextLink
        href={formattedUrl}
        prefetch={prefetch}
        className={classes}
        ref={innerRef as React.Ref<HTMLAnchorElement>}
        {...rest}
      >
        {children}
      </NextLink>
    )
  }
)

export default WebsiteLink
