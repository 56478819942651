import classnames from 'classnames'
import { Oval } from 'react-loader-spinner'

import styles from './index.module.scss'

export type SpinnerProps = {
  disabled?: boolean
  size?: 'large' | 'medium' | 'small' | 'x-small'
}

export const Spinner = ({ disabled = false, size = 'x-small' }) => {
  return (
    <Oval
      wrapperClass={classnames(styles['container'], styles[size], {
        [styles['disabled']]: disabled,
      })}
      ariaLabel="loading"
    />
  )
}

export default Spinner
