import React, { useMemo, forwardRef, useRef, useImperativeHandle, ReactNode, Context } from 'react'
import Link, { LinkProps } from 'next/link'
import classnames from 'classnames'
import {
  formatUrl,
  isExternalUrl,
  AmplitudeContext,
  useClickEventTracking,
  ContextType,
} from '@platform/utils'
import { Spinner } from '@platform/ui-kit'

import styles from './styles.module.scss'

export enum WebsiteButtonVariant {
  Ghost = 'ghost',
  GhostOnBrand = 'ghost-on-brand',
}

export enum WebsiteButtonOutlineVariant {
  Default = 'default',
  Primary = 'primary',
  Neutral = 'neutral',
}

type BaseProps = {
  variant?: 'ghost' | 'ghost-on-brand'
  outlineVariant?: 'default' | 'neutral' | 'primary'
  size?: 'large' | 'medium' | 'small'
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  disabled?: boolean
  startAdornment?: React.ElementType | Node | string
  endAdornment?: React.ElementType | Node | string
  endAdornmentClassName?: string
  href?: string
  className?: string
  children: ReactNode
  trackingIdentifier?: string
  prefetch?: boolean
  isExternal?: boolean
}

export type ButtonAsButton = BaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps>

type ButtonAsLink = BaseProps & Omit<LinkProps, keyof BaseProps>

type ButtonAsExternal = BaseProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps>

export type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink

export const WebsiteButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      className = '',
      variant = 'default',
      outlineVariant = 'default',
      size = 'medium',
      href,
      isLoading = false,
      children,
      startAdornment,
      isExternal = false,
      endAdornment,
      endAdornmentClassName,
      type = 'button',
      disabled = false,
      trackingIdentifier,
      prefetch,
      ...rest
    },
    ref
  ) => {
    const classes = classnames(
      styles['button'],
      styles[variant],
      styles[`outline-${outlineVariant}`],
      className
    )

    const innerRef = useRef<HTMLAnchorElement | HTMLButtonElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement | HTMLAnchorElement)

    useClickEventTracking(
      innerRef,
      AmplitudeContext as unknown as Context<ContextType>,
      href ? 'link_click' : 'button_click',
      {
        id: trackingIdentifier,
      }
    )

    const renderChildren = useMemo(() => {
      const StartAdornment = startAdornment as React.ElementType
      const EndAdornment = endAdornment as React.ElementType

      return (
        <>
          <span
            className={classnames(styles['label'], styles[size], {
              [styles['loading']]: isLoading,
            })}
          >
            {startAdornment && (
              <StartAdornment
                className={classnames(styles['adornment'], styles['start-adornment'])}
              />
            )}
            {children}
            {endAdornment && (
              <EndAdornment
                className={classnames(
                  styles['adornment'],
                  styles['end-adornment'],
                  endAdornmentClassName
                )}
              />
            )}
          </span>
          {isLoading && (
            <div className={styles['loading-container']}>
              <Spinner disabled={true} />
            </div>
          )}
        </>
      )
    }, [startAdornment, endAdornment, size, isLoading, children, endAdornmentClassName])

    if (href) {
      const formattedUrl = isExternal ? href : formatUrl(href)

      if (isExternalUrl(href) || isExternal) {
        return (
          <a
            className={classes}
            href={formattedUrl}
            ref={innerRef as React.Ref<HTMLAnchorElement>}
            {...(rest as ButtonAsLink)}
          >
            {renderChildren}
          </a>
        )
      }

      return (
        <Link
          href={formattedUrl}
          prefetch={prefetch}
          className={classes}
          ref={innerRef as React.Ref<HTMLAnchorElement>}
          {...(rest as ButtonAsLink)}
        >
          {renderChildren}
        </Link>
      )
    }

    return (
      <button
        className={classes}
        type={type}
        disabled={disabled || isLoading}
        ref={innerRef as React.Ref<HTMLButtonElement>}
        {...(rest as ButtonAsButton)}
      >
        {renderChildren}
      </button>
    )
  }
)

export default WebsiteButton
