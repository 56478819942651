import { useMemo, useCallback } from 'react'
import { Button, Link, Logo } from '@platform/ui-kit'
import { useQuery } from '@tanstack/react-query'
import classnames from 'classnames'
import { useRouter } from 'next/router'

import { formatHeaderColors, FormatHeaderProps } from '../../utils/helpers'
import { BurgerMenu } from '../burger-menu'
import WebsiteButton, { WebsiteButtonOutlineVariant } from '../website-kit/website-button'
import type { HeaderThemeValues } from '../../utils/constants'

import styles from './index.module.scss'

// TODO: Update links if necessary
const NAVIGATION_ITEMS = [
  {
    label: 'For talent',
    href: '/for-talent',
    activeHref: 'for-talent',
  },
  {
    label: 'For companies',
    href: '/for-companies',
    activeHref: 'for-companies',
  },
  {
    label: 'Case studies',
    href: '/case-studies',
    activeHref: 'case-studies',
  },
  {
    label: 'Blog',
    href: '/blog',
    activeHref: 'blog',
  },
  {
    label: 'About us',
    href: '/about-us',
    activeHref: 'about-us',
  },
]

type HeaderProps = {
  shouldChangeBgColor?: boolean
  headerInitialTheme?: HeaderThemeValues
}

export const Header = ({ shouldChangeBgColor = false, headerInitialTheme }: HeaderProps) => {
  const { data } = useQuery(['HeaderTheme'], { enabled: false })
  const { asPath } = useRouter()
  const [, parentRoutePath] = asPath.split('/')

  const { buttonNavVariant, hireOlbyButtonVariant, headerStyles, logoVariant } = useMemo(
    () => ({
      ...formatHeaderColors({ shouldChangeBgColor, data, headerInitialTheme } as FormatHeaderProps),
    }),
    [data, headerInitialTheme, shouldChangeBgColor]
  )

  const menuButtons = useMemo(() => {
    return (
      <>
        <WebsiteButton
          size="large"
          variant={buttonNavVariant.variant}
          outlineVariant={buttonNavVariant.outlineVariant}
          href={`${process.env.NEXT_PUBLIC_TALENT_URL}/sign-up?utm_source=site&utm_medium=referral`}
          isExternal
        >
          Join Olby
        </WebsiteButton>
        <Button
          size="large"
          variant={hireOlbyButtonVariant.variant}
          href={`${process.env.NEXT_PUBLIC_COMPANY_URL}/sign-up`}
          isExternal
          outlineVariant={hireOlbyButtonVariant.outlineVariant}
        >
          Hire Olby
        </Button>
      </>
    )
  }, [buttonNavVariant, hireOlbyButtonVariant])

  const buttonStyles = useCallback(
    (activeHref: string) => ({
      className: classnames({ [styles['active-route-button']]: activeHref === parentRoutePath }),
    }),
    [parentRoutePath]
  )

  return (
    <header className={classnames(styles['header'], styles[headerStyles])}>
      <nav className={styles['header-content']}>
        <BurgerMenu navItems={NAVIGATION_ITEMS} burgerIconClassName={headerStyles} />
        <Link
          aria-label="Olby logo"
          href="/"
          className={classnames(styles['link'], {
            [styles['outline-color-neutral']]:
              buttonNavVariant.outlineVariant === WebsiteButtonOutlineVariant.Neutral,
            [styles['outline-color-primary']]:
              buttonNavVariant.outlineVariant === WebsiteButtonOutlineVariant.Primary,
          })}
          prefetch={false}
        >
          <Logo variant={logoVariant} />
        </Link>
        <ul className={styles['navigation-wrapper']}>
          {NAVIGATION_ITEMS.map(({ label, href, activeHref }) => (
            <li key={label}>
              <WebsiteButton
                href={href}
                variant={buttonNavVariant.variant}
                outlineVariant={buttonNavVariant.outlineVariant}
                {...buttonStyles(activeHref)}
                size="large"
              >
                {label}
              </WebsiteButton>
            </li>
          ))}
        </ul>
        <div className={styles['buttons-wrapper']}>{menuButtons}</div>
      </nav>
    </header>
  )
}

export default Header
