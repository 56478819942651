import classnames from 'classnames'

import { ReactComponent as OlbyLogoSVG } from '../../assets/logos/olby-logo.svg'
import { ReactComponent as OlbyLogoIconSVG } from '../../assets/logos/olby-logo-icon.svg'

import styles from './logo.module.scss'

export enum LogoVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  White = 'white',
}

type LogoProps = {
  variant?: 'primary' | 'secondary' | 'white'
  className?: string
  isIconLogo?: boolean
}

export const Logo = ({ className, variant = 'primary', isIconLogo = false }: LogoProps) => {
  return isIconLogo ? (
    <OlbyLogoIconSVG className={classnames(className, styles['logo'], styles[variant])} />
  ) : (
    <OlbyLogoSVG className={classnames(className, styles['logo'], styles[variant])} />
  )
}

export default Logo
